.layout > div {
  display: grid;
  grid-template-columns: 200px 300px 200px;
  grid-gap: 32px;
  align-items: center;
  margin: 32px;
}

.layout label {
  display: block;
  margin: 0 0 8px 0;
}

.layout label span {
  float: right;
  font-size: 0.75em;
}

.layout .props div {
  margin: 0 0 10px 0;
}

.layout input, .layout select {
  width: 100%;
}

.layout .graph {
  position: relative;
  background: #ffda00;
  height: 150px;
}

.layout .graph p {
  position: absolute;
  bottom: 100%;
  right: 0;
  font-size: 0.7em;
  padding-bottom: 5px;
}

.layout .graph svg {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 302px;
  height: 152px;
  z-index: 4;
}

.layout .graph path {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2px;
  stroke-linecap: round;
}

.layout .graph .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.layout .graph .progress:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #2727e6;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.layout .graph .progress span {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 0.7em;
  text-align: center;
  transform: translate(-50%, 0);
}

.layout .in-area, .layout .out-area {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0,0,0,0.1);
  background: #fcc6cd;
}

.layout .out-area {
  left: auto;
  right: 0;
}

.layout .animation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout .animation .square {
  width: 100px;
  height: 100px;
  background-color: #2727e6;
}

.layout .animation .square.small {
  width: 50px;
  height: 50px;
}
* { margin: 0; box-sizing: border-box; }

html, body, #root, .App {
  min-height: 100vh;
}

.layouts {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ebebeb;
}

.layout:first-child {
  border-top: none;
}

.actions {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
}

.actions a {
  background-color: #111118;
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  margin-left: 2px;
}